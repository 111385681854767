import React, { useEffect } from "react";
import { BuilderComponent } from "@builder.io/react";
import { HeadProps, PageProps, graphql } from "gatsby";
import SEOMeta from "../../components/SEOMeta";
import { mxpSignupFirstView } from "../../utils/mixpanelEvents/mixpanelEvents";
import SPURLSearchParams from "../../services/SPURLSearchParams.class";
import styles from "./styles.module.scss";
import useSetPromocode from "../../hooks/useSetPromocode";
import { handleContentLoaded } from "@utils/builderContentLoaded";
import AlternativeHeaderSignUpForm from "@components/AlternativeHeaderSignUpForm/AlternativeHeaderSignUpForm";
import { RegisterComponentSignForm } from "@utils/RegisterComponentSignForm";
import { usePageModelData } from "@hooks/usePageModelData";
import { StringParam, useQueryParam } from "use-query-params";
import { Cookie } from "@utils/Cookie";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const model = "page-signup-form";

const PageSignup: React.FC<PageProps<DataProps>> = ({ location, data }) => {
  const {
    allBuilderModels: { onePageSignupForm },
  } = data;

  const [utmCampaign] = useQueryParam("utm_campaign", StringParam);
  const pmax = utmCampaign?.toLowerCase()?.includes("pmax");

  const content = usePageModelData<Queries.builder_PageSignupForm["content"]>({
    model,
    urlPath: location?.pathname,
    userAttr: {
      pmax:
        (typeof window !== "undefined" &&
          JSON.parse(sessionStorage.getItem("pmax") as string)) ||
        Boolean(pmax),
      isPartner: Boolean(Cookie.getCookie("IS_PARTNER")),
    },
  });

  const seoData = onePageSignupForm?.content
    ?.data as Queries.builder_PageSignupFormData;

  useEffect(() => {
    const isConsultcall = Boolean(
      SPURLSearchParams.get(location?.search, "consultcall")
    );

    mxpSignupFirstView("TESTGROUP_ID", isConsultcall);
  }, [location?.search]);

  useSetPromocode(seoData?.promoCode!);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: seoData?.backgroundColor! }}
    >
      <AlternativeHeaderSignUpForm />

      {content && (
        <BuilderComponent
          content={content}
          model={model}
          contentLoaded={handleContentLoaded}
        />
      )}
    </div>
  );
};

RegisterComponentSignForm();

export default PageSignup;

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePageSignupForm },
  } = data;

  const seoData = onePageSignupForm?.content
    ?.data as Queries.builder_PageSignupFormData;

  return (
    <SEOMeta
      title={seoData?.seoTitle!}
      description={seoData?.seoMetaDescription!}
      index={seoData?.index!}
    />
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePageSignupForm(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
