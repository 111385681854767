import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterFaqsContainer } from "@components/BuilderWrapperComponents/FaqsAccordionBC";
import { RegisterLinkRedirectSignin } from "@components/BuilderWrapperComponents/LinkRedirectSigninWrapper";
import { RegisterSignUpForm } from "@components/BuilderWrapperComponents/SignUpFormBC";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";

export const RegisterComponentSignForm = () => {
  RegisterSignUpForm();
  RegisterFaqsContainer();
  RegisterPricingPageToolTips();
  RegisterLinkRedirectSignin();
  RegisterSignupFormDrip();
};
